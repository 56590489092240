import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import rightSideNav from './images/right-sidenav-arrow.svg';
import localStorageUtil from '../../../utils/localStorage';

const SideNav = ({ menus }) => {
  const [open, setOpen] = useState(false);

  const userDetails = localStorageUtil.getItem('userDetails');

  return (
    <div
      className={` ${
        open ? 'w-64' : 'w-20 '
      } bg-[#151C2E]  pt-12 relative duration-300 flex-shrink-0 h-screen`}
    >
      <div className="m-5 text-white text-[8px] opacity-40 absolute bottom-0">
        {process.env.REACT_BUILD_VERSION}
      </div>

      <div
        className="fixed m-6 flex items-center overflow-hidden cursor-pointer z-[3] h-9 gap-x-4 whitespace-nowrap overflow-ellipsis"
        onClick={() => setOpen(!open)}
      >
        <img
          alt="logo"
          src={rightSideNav}
          className={`duration-500 ${open && 'rotate-180'}`}
        />
        <h1
          className={`text-[#ffefdb] origin-left font-medium text-xl duration-500 capitalize ${
            !open && 'scale-0'
          }`}
        >
          Hi {userDetails?.name || userDetails?.username || ''}
        </h1>
      </div>
      <ul className="fixed pt-16 m-5 bg-[#151C2E] rounded shadow z-1">
        {menus?.map((menu, index) => (
          <NavLink
            key={index}
            to={menu?.navigate}
            className={({ isActive, isPending }) =>
              `flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white text-base items-center gap-x-4 
              ${menu.gap ? 'mt-9' : 'mt-2'} ${
                index === 0 && 'bg-light-white'
              } ${isPending ? 'pending' : isActive ? 'active bg-[#495560] text-white' : 'opacity-90'}`
            }
          >
            {/* <img alt={menu.title} src={menu?.src} /> */}
            {menu?.src}
            <span className={`${!open && 'hidden'} origin-left duration-200`}>
              {menu.title}
            </span>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

SideNav.propTypes = {
  menus: PropTypes.shape({
    map: PropTypes.func,
  }),
};
export default SideNav;
